import React from 'react';
import { LoadingDots } from '@balance-web/loading';
import { Stack } from '@balance-web/stack';
import { Text } from '@balance-web/text';
import { Flex, FlexProps } from '@balance-web/flex';

type LoadingDotsProps = Parameters<typeof LoadingDots>[0];

export type LoaderProps = LoadingDotsProps & {
  inline?: boolean;
  visibleLabel?: boolean;
} & FlexProps;

// TODO: graduate or merge with DS component
export const Loader: React.FC<LoaderProps> = ({
  /**
   * Should this just be an inline element, or fill the entire parent?
   */
  inline,
  /**
   * Label to display on mouseover, or as description when `visibleLabel` is true
   */
  label,
  /**
   * Make `label` always visible
   */
  visibleLabel,

  /**
   * Rest of the props that LoadingDots takes
   */
  ...props
}) => {
  if (inline) return <LoadingDots label={label} {...props} />;

  return (
    <Flex alignItems="center" justifyContent="center" flexGrow={1} {...props}>
      <Stack gap="xlarge" align="center">
        <LoadingDots label={label} />
        {!!label && visibleLabel && (
          <Text align="center" color="muted" size="small">
            {label}
          </Text>
        )}
      </Stack>
    </Flex>
  );
};
