// ts-gql-integrity:b7afe491cd58ff27749b13c921699915
/*
ts-gql-meta-begin
{
  "hash": "ee2b3747d5cad78fa9a22322af2c06a0"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type RefreshAuthTokenMutationMutationVariables = SchemaTypes.Exact<{
  refreshToken: SchemaTypes.Scalars['String'];
}>;


type RefreshAuthTokenMutationMutation = { readonly __typename: 'Mutation', readonly reAuthenticatePortalUser: { readonly __typename: 'AuthenticatedToken', readonly token: string, readonly refreshToken: string } | null };



export type type = TypedDocumentNode<{
  type: "mutation";
  result: RefreshAuthTokenMutationMutation;
  variables: RefreshAuthTokenMutationMutationVariables;
  documents: SchemaTypes.TSGQLDocuments;
  fragments: SchemaTypes.TSGQLRequiredFragments<"none">
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    RefreshAuthTokenMutation: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"mutation\",\"name\":{\"kind\":\"Name\",\"value\":\"RefreshAuthTokenMutation\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"refreshToken\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}},\"directives\":[]}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"reAuthenticatePortalUser\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"refreshToken\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"refreshToken\"}}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"token\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"refreshToken\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
