import React, { useMemo } from 'react';
import { Dropdown } from '@balance-web/dropdown-v2';
import { Text } from '@balance-web/text';
import { Divider } from '@balance-web/divider';
import {
  ExternalLinkIcon,
  GridIcon,
  HelpCircleIcon,
  LogOutIcon,
} from '@balance-web/icon';

import { AppHeader } from '@reckon-web/app-header';
import { useAuth } from '@reckon-web/auth-store';

import { Config } from '../../../services/Config';
import { useAuthenticatedUser } from '../../../services/Auth';
import { AppName } from '../../../constants';
import { useNavigateToPortal } from '../../../services/Navigation/useNavigateToPortal';

export const SiteHeaderContainer = () => {
  const auth = useAuth();
  const authenticatedUser = useAuthenticatedUser();
  const portalNavigation = useNavigateToPortal();

  const tenancyIconURL = authenticatedUser.user?.tenancyIconURL || '';
  const tenancy = authenticatedUser.user?.tenancyId;
  const appName = tenancy ? AppName[tenancy] : '';
  const portalUrl = portalNavigation.createPortalUrl('/dashboard') || '';

  const userMenu = useMemo(() => {
    if (authenticatedUser.isLoading) {
      return undefined;
    }

    if (!auth.isAuthenticated) {
      return undefined;
    }

    const userFullName = authenticatedUser.user?.fullName;
    const userEmail = authenticatedUser.user?.email;

    if (!userFullName || !userEmail || !portalUrl) {
      return undefined;
    }

    return {
      userFullName,
      userEmail,
      items: (
        <React.Fragment>
          <Dropdown.Item
            startElement={<HelpCircleIcon size="small" color="muted" />}
            href={Config.APPLICATION_HELP_AND_SUPPORT_URL}
            target="_blank"
          >
            Help & Support
          </Dropdown.Item>

          <Dropdown.Item
            startElement={<GridIcon size="small" color="muted" />}
            endElement={<ExternalLinkIcon size="small" color="muted" />}
            href={portalUrl}
            target="_blank"
          >
            <Text>Go to Portal</Text>
          </Dropdown.Item>
          <Divider />
          <Dropdown.Item
            startElement={<LogOutIcon size="small" color="muted" />}
            onSelect={() => {
              auth.logout({ reasonCode: 'USER_INITIATED' });
            }}
          >
            <Text>Sign out</Text>
          </Dropdown.Item>
        </React.Fragment>
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalUrl, authenticatedUser]); // purposely omitting auth.logout from dependencies

  return (
    <AppHeader
      appIconUrl={tenancyIconURL}
      appName={appName}
      isLoading={authenticatedUser.isLoading}
      appVersion={Config.APPLICATION_VERSION}
      userMenu={userMenu}
    />
  );
};
