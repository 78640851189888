import React from 'react';

import { useAuth } from '../Auth';
import { Loader } from '../../components/common/Loader';

import { SiteRoutesContext } from './SiteRoutesContext';
import { Routes } from './Routes';
export type SiteRoutesProviderProps = {
  currentPath?: string;
};
export const SiteRoutesProvider: React.FC<SiteRoutesProviderProps> = ({
  children,
  currentPath,
}) => {
  const auth = useAuth();

  const isSecureRoute = (current: string) => {
    return Object.values(Routes).some((item) => {
      if (!('secured' in item)) {
        return false;
      }
      return item.secured && item.route.isMatch(current);
    });
  };

  const isAllowed =
    auth.isAuthenticated && currentPath && isSecureRoute(currentPath);

  React.useEffect(() => {
    if (!!auth.isLoading) {
      return;
    }

    if (!isAllowed) {
      auth.logout({ reasonCode: 'SESSION_EXPIRED' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, auth.isLoading, currentPath]);

  if (auth.isLoading) {
    return <Loader label="loading" />;
  }

  if (!isAllowed) {
    return <Loader label="redirecting" />;
  }

  return (
    <SiteRoutesContext.Provider value={{ routes: Routes, currentPath }}>
      {children}
    </SiteRoutesContext.Provider>
  );
};
