import React from 'react';

import { AuthenticatedUserContext } from './AuthenticatedUserContext';

export function useAuthenticatedUser() {
  const context = React.useContext(AuthenticatedUserContext);
  if (!context) {
    throw new Error(
      'useAuthenticatedUser can only be used under <AuthenticatedUserProvider />'
    );
  }
  return context;
}
