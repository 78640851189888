import { Route as UniversalRoute } from 'next-universal-route';

export const Routes = {
  selectApp: {
    route: new UniversalRoute('/', ''),
    secured: true,
  },
  selectBook: {
    route: new UniversalRoute('/book', 'book'),
    secured: true,
  },
} as const;
