import { useMemo } from 'react';

import { gql, useQuery } from '@reckon-web/gql-api-client';

export const GetCurrentUserQuery = gql`
  query GetCurrentUserQuery {
    authenticatedUser {
      id
      firstName
      lastName
      email
      tenancyIconURL
      tenancyId
      userProducts {
        productType
        url
      }
    }
  }
` as import('../../../__generated__/ts-gql/GetCurrentUserQuery').type;

export type User = typeof GetCurrentUserQuery['___type']['result']['authenticatedUser'];

type GetCurrentUserQueryProps = {
  skip?: boolean;
};

export const useGetCurrentUserQuery = ({ skip }: GetCurrentUserQueryProps) => {
  const { loading, data, error } = useQuery(GetCurrentUserQuery, {
    skip,
  });

  const user = useMemo(() => {
    const user = data?.authenticatedUser || {};
    return isUser(user)
      ? { ...user, fullName: [user.firstName, user.lastName].join(' ') }
      : undefined;
  }, [data]);
  return {
    user,
    isLoading: loading,
    error: (error instanceof Error && error.message) || '',
  };
};

export function isUser(user: unknown): user is User {
  if (typeof user === 'object' && user !== null) {
    return 'id' in user && 'firstName' in user && 'lastName' && 'email' in user;
  }

  return false;
}
