import { Config } from '../services/Config';

export const PortalURLs: Record<string, string> = {
  RECKON: Config.APPLICATION_RECKON_PORTAL_URL,
  DEADLYDIGITS: Config.APPLICATION_DEADLYDIGITS_PORTAL_URL,
  ALL: Config.APPLICATION_RECKON_PORTAL_URL,
};

export function isPortalUserTenancy(
  value?: string | null
): value is keyof typeof PortalURLs {
  if (!value) {
    return false;
  }

  return value in PortalURLs;
}
