import { useCallback, useMemo } from 'react';

import type { LogoutHandler, LogoutReasonCode } from '@reckon-web/auth-store';

import { PortalURLs, isPortalUserTenancy } from '../../constants/PortalURLs';

/**
 * Retrieves the encoded book switcher URL for the specified user tenancy.
 *
 * @param {string} userTenancy - The user tenancy. Defaults to 'RECKON' if not provided because user is not authenticated.
 * @returns {string} - The encoded book switcher URL for the user tenancy.
 */

export function useNavigateToPortal(userTenancy: string | null = 'RECKON') {
  /**
   * A prepared function that encodes the portal URL for the specified user tenancy.
   */
  const createPortalUrl = useMemo(() => {
    if (!isPortalUserTenancy(userTenancy)) {
      return () => {};
    }

    const baseUrl = PortalURLs[userTenancy];
    const result = createUrlEncoder(baseUrl);

    return result;
  }, [userTenancy]);

  const navigateToPortal = (pathname?: string) => {
    const url = createPortalUrl(pathname);

    if (!url) {
      return;
    }
    window.location.href = url;
  };

  const createLogoutUrl = useCallback(
    (options: Parameters<LogoutHandler>[0]) => {
      if (!isPortalUserTenancy(userTenancy)) {
        return;
      }

      const target = PortalURLs[userTenancy];
      const source = window.location.origin;

      const result = encodeBookswitcherSelfPortalUrl({
        target,
        source,
        reasonCode: options.reasonCode,
        pathname: '/logout',
      });

      return result;
    },
    [userTenancy]
  );

  const navigateToLogout: LogoutHandler = (options) => {
    const href = createLogoutUrl(options);
    if (!href) {
      return;
    }

    window.location.href = href;
  };

  return {
    navigateToLogout,
    createLogoutUrl,
    navigateToPortal,
    createPortalUrl,
  };
}

export function encodeBookswitcherSelfPortalUrl({
  target,
  reasonCode,
  pathname,
  source,
}: {
  target: string;
  reasonCode: LogoutReasonCode;
  pathname?: string;
  source: string;
}) {
  const encoder = createUrlEncoder(target);

  // URLSearchParams is awesome! it encodes the query string for us
  const url = encoder(pathname || '', {
    redirectURL: source,
  });

  const result = url.toString();

  return result;
}

export function createUrlEncoder(url: string) {
  const target = new URL(url);
  const current_pathname = getPathFromHashRouterUrl(target);
  const current_params = getParamsFromHashRouterUrl(target);

  const mergeParams = (params?: URLSearchParams | Record<string, any>) => {
    const new_params = new URLSearchParams(current_params);

    if (!params && current_params) {
      return current_params;
    }

    if (!params) {
      return;
    }

    if (params instanceof URLSearchParams) {
      params.forEach((value, key) => {
        new_params.set(key, value);
      });
      return new_params;
    }

    if (typeof params === 'object') {
      Object.entries(params).forEach(([key, value]) => {
        new_params.set(key, value);
      });
      return new_params;
    }

    return new_params;
  };

  return (
    pathname?: string,
    params?: URLSearchParams | Record<string, any>
  ) => {
    const output = new URL(url);

    const new_params = mergeParams(params);

    output.hash = [
      '#',
      // the new pathname
      pathname || current_pathname || '',
      // the merged params
      new_params && '?' + new_params.toString(),
    ].join('');

    return output.toString();
  };
}

// https://regex101.com/r/ZSCGRK/1
const HashRouterPathnamePattern = /#(.*)\?/g;
export function getPathFromHashRouterUrl(url: URL): string {
  // reset the pattern since global patterns are stateful
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#description
  HashRouterPathnamePattern.lastIndex = 0;
  const match = HashRouterPathnamePattern.exec(url.hash);
  if (match) {
    return match[1];
  }
  return '';
}

// https://regex101.com/r/sWhHKQ/1
const HashRouterParamPattern = /#.*\?(.+)/g;
/**
 * Extracts the URLSearchParams from the hash router URL.
 *
 * Any encoded values are then automatically decoded.
 */
export function getParamsFromHashRouterUrl(
  url: URL
): URLSearchParams | undefined {
  // reset the pattern since global patterns are stateful
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#description
  HashRouterParamPattern.lastIndex = 0;
  const match = HashRouterParamPattern.exec(url.hash);
  if (match) {
    return new URLSearchParams(match[1]);
  }
  return;
}
