import type { ReactNode } from 'react';
import React from 'react';

import { useAuth as useAuthStore } from '@reckon-web/auth-store';

import { useGetCurrentUserQuery } from './useGetCurrentUserQuery';
import { AuthenticatedUserContext } from './AuthenticatedUserContext';

type AuthenticatedUserProviderProps = {
  children: ReactNode;
};
export function AuthenticatedUserProvider({
  children,
}: AuthenticatedUserProviderProps) {
  const authStore = useAuthStore();
  const currentUserQuery = useGetCurrentUserQuery({
    skip: !authStore.isAuthenticated,
  });

  return (
    <AuthenticatedUserContext.Provider value={currentUserQuery}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
}
