import React from 'react';
import type { PropsWithChildren } from 'react';

import { AuthProvider as AuthStoreProvider } from '@reckon-web/auth-store';

import { Config } from '../Config';
import { Loader } from '../../components/common/Loader';

import { UrlFragmentManager } from './UrlFragmentManager';

export type AuthProviderProps = PropsWithChildren<{
  storagePrefix?: string;
  logoutUrl?: string;
}>;

export const AuthProvider = ({
  children,
  storagePrefix = Config.APPLICATION_LOCALSTORAGE_PREFIX,
}: AuthProviderProps) => {
  return (
    <AuthStoreProvider storagePrefix={`${storagePrefix}`} onLogout={() => {}}>
      <UrlFragmentManager>
        <AuthStoreProvider.IsLoading loader={<Loader label="Loading" />}>
          {children}
        </AuthStoreProvider.IsLoading>
      </UrlFragmentManager>
    </AuthStoreProvider>
  );
};
